<template>
	<div class="containner">
		<div class="login">
			<div class="logo">
				<i class="el-icon-user" style=""></i>
			</div>
			<div class="login_name">
				<!-- <image :src="codeUrl"></image> -->
				<p>Osaio1.0</p>
			</div>
			<el-form>
				<el-form-item>
					<el-input @keyup.enter="checkInCorrect()" id="username" v-model.trim="account" placeholder="登录账号"></el-input>
				</el-form-item>
				<el-form-item>
					<div style="width: 100%">
						<el-input @keyup.enter="checkInCorrect()" class="codeinput" id="codeinput" v-model.trim="codeValue" placeholder="验证码：" style="float: left"></el-input>
						<el-button type="primary" :disabled="account==''" v-if="showGetCodeButton" @click="Getcode()"> 获取验证码</el-button>
						<img v-if="!showGetCodeButton" :src="codeUrl" @click="Getcode" alt="" style="float: right;height: 36px;  background-color: white;  margin-top: 2px; " />
					</div>
				</el-form-item>
				<el-form-item>
					<el-input @keyup.enter="checkInCorrect()" type="password" id="password" v-model.trim="password" placeholder="登录密码"></el-input>
				</el-form-item>

				<el-form-item>
					<!-- <el-checkbox id="remember_password" v-model="checked" class="savepass" style="font-size:12px">保存登录状态</el-checkbox> -->
					<el-button style="width: 100%;" @click="checkInCorrect()" type="primary">登录</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- <div class="footer">
        <p style="color:rgb(161, 161, 161)">
          <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">粤ICP备19052100号</a>
          COPYRIGTH © 2018<br />
          powered by：www.nooie.cn
        </p>
      </div> -->
	</div>
</template>

<script>
	import md5 from "js-md5";
	import Cryptojs from "crypto-js";
	export default {
		name: "Login",
		data() {
			return {
				account: "",
				password: "",
				codeValue: "",
				codeId: "",
				codeUrl: "",
				showGetCodeButton: true,
			};
		},
		mounted() {},
		methods: {
			// 签名结果
			signature(request_id, param, headers, method) {
				var signature = this.GetHeaderString(headers);
				var queryBody = "";
				if (method === "get") {
					queryBody = this.SortString(param);
				} else {
					queryBody = JSON.stringify(param);
				}
				queryBody = Cryptojs.MD5(queryBody).toString().toUpperCase();
				signature = signature + queryBody;
				return Cryptojs.HmacSHA256(signature, request_id + "");
			},
			GetHeaderString(headers) {
				var keys = headers["CA-KEYS"].split(",");
				var signHeader = [];
				for (const v of keys) {
					signHeader[v] = headers[v];
				}
				var str = this.SortString(signHeader);
				return str;
			},
			SortString(obj) {
				var keys = Object.keys(obj).sort();
				var str = "";
				keys.forEach((key) => {
					if (str == "") {
						str += key + "=" + obj[key];
						return true;
					}
					str += "&" + key + "=" + obj[key];
				});
				return str;
			},
			Getcode() {
				let timestamp = new Date().getTime();
				let header = {
					request_id: timestamp,
					client_time: parseInt(timestamp / 1000),
					"CA-KEYS": "client_time,request_id",
					region: "gb",
				};
				let param = {
					account: this.account,
					height: 37,
					width: 110,
					length: 4,
					max_skew: 0.3,
					dot_count: 50,
				};
				header.signature = this.signature(timestamp, param, header, "get");
				this.$axios
					.get("/admin/v1/login/code", {
						params: param,
						headers: header,
					})
					.then((res) => {
						if (res.data.code === 200) {
							this.codeUrl = res.data.data.bs;
							this.codeId = res.data.data.id;
							this.showGetCodeButton = false;
							return;
						}
						this.$message.error("获取验证码失败！");
					});
			},
			checkInCorrect() {
				if (document.getElementById("username").value == "") {
					this.$message("请输入用户名！");
					document.getElementById("username").focus();
					return false;
				}
				if (document.getElementById("password").value == "") {
					this.$message("请输入密码！");
					document.getElementById("password").focus();
					return false;
				}
				if (document.getElementById("codeinput").value == "") {
					this.$message("请输入验证码！");
					document.getElementById("codeinput").focus();
					return false;
				} else {
					this.saveInfo();
					this.login();
					return true;
				}
			},
			saveInfo() {
				try {
					var isSave = this.checked; //保存按键是否选中
					if (isSave) {
						var username = document.getElementById("username").value;
						var password = document.getElementById("password").value;
						if (username != "" && password != "") {
							this.SetCookie(username, password);
						}
					} else {
						this.SetCookie("", "");
					}
				} catch (e) {
					console.log(e);
				}
			},
			SetCookie(username, password) {
				document.cookie = "username=" + "";
				var Then = new Date();
				Then.setTime(Then.getTime() + 604800000); // 一星期后过期
				document.cookie =
					"username=" +
					username +
					"%%" +
					password +
					";expires=" +
					Then.toGMTString();
			},
			login: function() {
				let this_ = this;
				let timestamp = new Date().getTime();
				let header = {
					request_id: timestamp,
					client_time: parseInt(timestamp / 1000),
					"CA-KEYS": "client_time,request_id",
					region: "gb",
				};
				let param = {
					account: this.account,
					password: md5(this.password),
					verify_id: this.codeId,
					verify_value: this.codeValue,
				};
				header.signature = this.signature(timestamp, param, header, "post");
				this.$axios
					.post("/admin/v1/login/in", param, {
						headers: header
					})
					.then((res) => {
						if (res.data.code === 200) {
							localStorage.setItem("user_info", JSON.stringify(res.data.data));
							localStorage.setItem("is_login", true);
							localStorage.setItem(
								"password_status",
								res.data.data.password_status
							);
							let isGb = false;
							res.data.data.region_list.forEach((item) => {
								if (item.code == "gb") {
									localStorage.setItem("region_change", "gb");
									this_.GetAllMenu();
									isGb = true;
								}
							});
							if (!isGb) {
								localStorage.setItem(
									"region_change",
									res.data.data.region_list[0].code
								);
								this_.GetAllMenu();
							}
						} else {
							this.$message(res.data.msg);
							this_.Getcode();
						}
					});
			},
			GetAllMenu() {
				this.$router.push({
					path: "/index"
				}).catch((err) => {
					console.log(err);
				});
				// this.api.AdminMenu({}).then(res => {
				//    if (res.data.code == 200) {
				//     localStorage.setItem('allmenu', JSON.stringify(res.data.data))
				//     this.$router.push({path: '/index'}).catch(err => {
				//       console.log(err)
				//     })
				//     return
				//   }
				//   this.$message.error('获取菜单失败' + res.data.msg)
				// })
			},
		},
		created() {
			// this.Getcode();
			// if (localStorage.getItem("is_login")) {
			//   this.GetAllMenu();
			// }
		},
	};
</script>

<style scoped>
	.containner {
		height: 100%;
		background-color: black;
		background-image: url(../assets/image/1.jpg);
		background-size: 100% 100%;
	}

	a {
		color: white;
	}

	a:hover {
		color: rgb(253, 124, 65);
	}

	.el-alert__content {
		margin: 0px auto;
	}

	.el-alert {
		width: 322px;
		margin: 0px auto;
	}

	.el-checkbox__label {
		font-size: 12px !important;
	}

	/* .el-checkbox, */
	/* .savepass {
    display: block;
    text-align: left;
    margin-left: 150px
  } */

	.login {
		z-index: 1000;
		width: 400px;
		height: 445px;
		background-color: rgba(221, 231, 243, 0.5);
		padding: 50px;
		box-sizing: border-box;
		float: right;
		margin-right: 200px;
		position: relative;
		/* margin-top: 200px; */
		top: 25%;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	}

	.logo {
		width: 120px;
		height: 120px;
		border: 5px solid rgb(255, 115, 0);
		border-radius: 100px;
		background-color: #fff;
		text-align: center;
		line-height: 110px;
		position: absolute;
		top: -60px;
		right: 140px;
		font-size: 70px;
		color: rgb(255, 115, 0);
	}

	.login_name {
		width: 100%;
		float: left;
		text-align: center;
		margin-top: 20px;
	}

	.login_name p {
		width: 100%;
		text-align: center;
		font-size: 18px;
		color: #444;
		padding: 10px 0 20px;
		font-weight: 700;
	}

	.savepass {
		float: left;
	}

	.footer {
		position: fixed;
		bottom: 50px;
		font-size: 14px;
		color: #fff;
		display: block;
		width: 100%;
		float: left;
		text-align: center;
		margin-top: 80px;
	}

	.codeinput {
		width: 60%;
	}
</style>
